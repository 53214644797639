// Add your JS customizations here

jQuery(function () {
    jQuery(window).scroll(function() {
        var header = jQuery('header#header');
        var scrollPosition = jQuery(window).scrollTop();

        if (scrollPosition > 10) { // Adjust the scroll position threshold as needed
        header.addClass('active');
        } else {
        header.removeClass('active');
        }
    });

    var hero_slider = UIkit.slideshow('.hero-banner .uk-slideshow');
    jQuery('.hero-banner .slider-prev').click(function(e) {
        e.preventDefault();
        hero_slider.show(hero_slider.index - 1);
    });
    jQuery('.hero-banner .slider-next').click(function(e) {
        e.preventDefault();
        hero_slider.show(hero_slider.index + 1);
    });

    // var logo_carousel = UIkit.slider('.logo-carousel .uk-slider');
    // jQuery('.logo-carousel a[uk-slidenav-previous]').click(function(e) {
    //     console.log('prev');
    //     e.preventDefault();
    //     logo_carousel.show(logo_carousel.index - 1);
    // });
    // jQuery('.logo-carousel a[uk-slidenav-next]').click(function(e) {
    //     console.log('next');
    //     e.preventDefault();
    //     logo_carousel.show(logo_carousel.index + 1);
    // });

    jQuery('.logo-carousel .uk-slider').each(function() {
        var logo_carousel = UIkit.slider(this);
        var $carousel = jQuery(this).closest('.logo-carousel');
    
        $carousel.find('a[uk-slidenav-previous]').click(function(e) {
            console.log('prev');
            e.preventDefault();
            logo_carousel.show('previous');
        });
    
        $carousel.find('a[uk-slidenav-next]').click(function(e) {
            console.log('next');
            e.preventDefault();
            logo_carousel.show('next');
        });
    });

    // get in touch modal toggle
    jQuery(".button").each(function() {
        if (jQuery(this).attr('href') == '#get-in-touch') {
            jQuery(this).attr('uk-toggle', '');
        }
    });

    jQuery(".main-menu ul li.menu-item-59 > a").click(function() {
        var url = window.location.protocol+'//'+window.location.hostname+'/services/';
        window.location.href = url;
    });

    jQuery(".main-menu ul li.menu-item-518 > a").click(function() {
        var url = window.location.protocol+'//'+window.location.hostname+'/about/';
        window.location.href = url;
    });

    jQuery(".main-menu ul li.menu-item-68 > a").click(function() {
        var url = window.location.protocol+'//'+window.location.hostname+'/projects/';
        window.location.href = url;
    });

    function handleResizeAndLoad() {
        jQuery(".featured-post-slideshow").each(function() {
            var block_height = jQuery(this).find('.content').outerHeight() + 155;
            jQuery(this).find('.uk-slideshow-items').css('min-height', block_height);
        });

        // Your code here
        // alert("Window loaded or resized");
        // Add your logic for both load and resize events
    }

    // Call the function on page load
    handleResizeAndLoad();

    // Bind the function to the resize event
    jQuery(window).on('resize', handleResizeAndLoad);

    // jQuery('.header-nav .main-menu ul li.menu-item a[data-toggle=dropdown]').on('mouseenter', function() {
    //     jQuery('.site-main').addClass('dd-open');
    // }).on('mouseleave', function() {
    //     jQuery('.site-main').removeClass('dd-open');
    // });

    jQuery(document).ready(function($) {
        jQuery('input[type="checkbox"]').change(function() {
            var label = jQuery(this).parent('label');
            if (jQuery(this).is(':checked')) {
                label.addClass('checked-label');
            } else {
                label.removeClass('checked-label');
            }
        });
    });

    jQuery('#copy-btn').click(function(){
        // Select the text field
        var copyText = jQuery('#copy-link');

        // Select the text inside the text field
        copyText.select();
        copyText[0].setSelectionRange(0, 99999); // For mobile devices

        // Copy the text inside the text field
        document.execCommand("copy");

        // Change the button text to 'Copied!'
        var button = jQuery(this);
        button.text('Copied!');

        // Set a delay to change the text back to 'Copy'
        setTimeout(function(){
            button.text('Copy');
        }, 1000);
    });

    document.addEventListener( 'wpcf7mailsent', function( event ) {
        function closeModal() {
            UIkit.modal('#get-in-touch').hide();
        }

        setTimeout(closeModal, 2000);
    }, false );

    jQuery('.wysiwyg ul li').each(function() {
        // Check if the current <li> has an immediate <h4> child
        if (jQuery(this).children('h4').length > 0) {
            jQuery(this).addClass('has-h4');
        } 
    });
});